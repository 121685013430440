@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/mui/base/fontSizes.module.scss';

.gridSlider {
  margin-bottom: $base * 6;

  @include mediaMin('md') {
    margin-bottom: 0;
  }

  :global(.splide__pagination) {
    bottom: -$base * 4 !important;
  }

  :global(.splide__pagination__page) {
    height: 13px !important;
    width: 13px !important;
    background: $parisWhite !important;
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  :global(.splide__pagination__page.is-active) {
    background: $primary !important;
  }
}

.gridItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  :hover {
    background-color: $primaryHighlight;
  }
}

.cardContainer {
  height: 100%;
  width: 100%;
  padding: $base * 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border: 2px solid $primary;
  border-radius: $base;
}

.profilePictureContainer {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid $gray;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 2 * $base;

  & img {
    width: 100%;
  }
}

.titleContainer {
  color: $primary;
  margin-bottom: $base;
  .title {
    font-weight: 500;
  }
}

.contactInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: $base * 3;
  gap: $base * 0.15;
}

.contactInfoItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: $base;
  &:last-child {
    margin-bottom: 0;
  }
}

.contactInfoKey {
  display: flex;
  align-items: center;
  color: $textPrimary;
}

.contactInfoIcon {
  width: $base * 2.5;
  height: $base * 2.5;
  margin-right: $base;
  color: $textSecondary;
}

.link {
  color: $primaryLight;
}

.highlight {
  color: $textInvertedPrimary;
  .cardContainer {
    border: 2px solid $secondary;
  }
  .contactInfoIcon {
    color: $textInvertedPrimary;
  }
  .contactInfoKey {
    color: $textInvertedPrimary;
  }
}
